:root {
  --topbar-height: 4.11vh;

  
  
  image-rendering: pixelated;
  color: var(--text-statsheet-color);

  --main-font: mainFont, sans-serif; 
}

@font-face {
  font-family: mainFont;
  src: url('/Art/UI/fonts/PTSerif-Regular.ttf');
}



.TopBar{
  width: 100%;
  height: var(--topbar-height);
  background-image: url('/Art/UI/TopbarBG.png');
  float: left;
  overflow: initial;
  z-index: 100;
}

.TopBarBorder{
  width:100%;
  position: absolute;
  top:calc(var(--topbar-height) + 1px);
  height: 2px;
  background-color: var(--color-green-dark);
}

.TopBarHeader{
  margin: auto;
  position: absolute;
  left: calc((100% - 42.33vh) / 2);
  top:0%;
  background-image: url('/Art/UI/TopbarHeader.png');
  width: 42.33vh;
  height: 8.78vh;
  z-index: 1;
  background-size: cover;;
  /* background-color: red; */
}

.TopBarTitle{
  margin: auto;
  position: absolute;
  left: calc((100% - 21.22vh) / 2);
  top:1.67vh;
  background-image: url('/Art/UI/TopbarTitle.png');
  width: 21.22vh;
  height: 3.22vh;
  z-index: 2;
  background-size: cover
}

.TopBarIcon{
  float: right;
  margin-right: 2.44vh;
  margin-top: 1.22vh;
  margin-left: -0.2vh;
  cursor: pointer;
  /* z-index: 100; */
}

.TBContent{
  position: absolute;
  width: 100%;
  /* background-color: blue; */
  z-index: 50;
}

.Statsheet{
  background-color: var(--color-green-mid);
  font-size: 70%;
  padding-top: 1.75vh;
  scroll-margin: 200px;
  
  padding-left: 1.25vw;

  overflow-y: scroll;
overflow-x: hidden;
  /* padding-right: -1vw; */
}

/* .Statsheet.Left{
  left:3.31vw;
}
.Statsheet.Right{
  right:3.31vw;
} */
.SSBorder{
  position: absolute;
  /* top: calc(11.78vh - 0.365vw);
  
  width: calc(15.75vw + 0.375vw);
  height: calc(50.11vh + 0.7vw); */
  border: 0.1875vw var(--color-brown-light) solid;
}
/* .SSBorder.Left{
  left: 2.95vw;
}
.SSBorder.Right{
  right:2.95vw;
} */
.SSCornerU{
  position: fixed;
  width:3.75vw;
  z-index: 2;
}
.SSCornerD{
  position: fixed;
  width:4.5625vw;
  z-index: 2;
}
.SSIcon{
  position: absolute;
  top: calc(11.78vh - 1.4vw);
  width: 2.6875vw;
  height: 2.5vw;
  z-index: 2;
}
.SSIcon.Left{
  left: 9.65vw;
}
.SSIcon.Right{
  right:9.65vw;
}
.SSIcon.PetProfile{
  left: 22vw;
  /* width: 6vw; */
}
.SSIcon.PetProfile.Right{
  left: 74.65vw;
  /* background-color: red; */
}
.Portrait{
  position: absolute;
  z-index: 2;
}
.Portrait.BG{
  z-index: 1;
  width: 2.6875vw;
  height: 2.5vw;
}
.Portrait.FG{
  z-index: 4;
  width: 2.6875vw;
  height: 2.5vw;
}
.Portrait.BG.Big{
  z-index: 1;
  width: 3.84vw;
  height: 3.57vw;
}
.Portrait.FG.Big{
  z-index: 4;
  width: 3.84vw;
  height: 3.57vw;
}
.Portrait.PCanvas{
  /* background-color: red; */
  width: 2.3vw;
  height: 2.3vw;
  top:0.1vw;
  left:0.21vw;
  /* top: 2vw; */
}
.Portrait.PCanvas.Left{
  transform: scaleX(-1);
  /* left:-5vw; */
}
.Portrait.PCanvas.Big{
  /* background-color: red; */
  left:0.27vw;
  top:0.1vw;
  height: 3.35vw;
  width: 3.35vw;
}

.Portrait.MonFace{
  width:10vw;
  height:10vw; 
  transform-Origin: 54% 87.5%;
}
.Portrait.MonFace.Big{
  width:14.286vw;
  height:14.286vw;
}
/* .Portrait.Left{
  transform: scaleX(-1);
  left:-5vw;
} */
.SSName{
  float:left;
  width:120%;
  text-align: center;
  font-size: 150%;
  color:var(--color-yellow-bold);
  margin-top: 1vh;
  margin-left: -12%;
  /* background-color: red; */
}
.SSName.ProfileName{
  margin-top: 1.8vh;
}
.SSInfo{
  float:left;
  width:120%;
  text-align: center;
  font-size: 100%;
  margin-left: -12%;
}
.SSLink{
  position: absolute;
  right:6%;
  top:1.5vh;
  z-index: 5;
}
.SSLink.SSBuyToggle{
  right:14%;
  cursor: pointer;
}
.SSLinkImg{
  width:1vw;
}
.SSLinkImg:hover{
  width:1.25vw;
  margin-top: -0.125vw;
  margin-right: -0.125vw;
}
.SSHeader{
  float:left;
  width:100%;
  color:var(--color-brown-dmid);
  margin-left:calc(var(--font-size)/ -1.5);
  font-weight: bold;
  margin-top:0.75vw;
}
.SSHeaderDots{
  width:calc(var(--font-size)/3);
  height:calc(var(--font-size)/3);
  margin-bottom: .09vw;
  margin-left: -0.05vw;
  margin-right: 0.05vw;
  /* float: */
}
/* .SSHeader.PCProfile{
  font-size: 120%;
} */
/* .SSHalfLine{
  float:left;
  width: 100%;
  height:0.3vw;
} */
.Stat.Highlight{
  color:var(--highlight-color);
}
.Stat.CanPop{
  color:var(--color-yellow-bold);
  font-weight: bold;
}
.Stat.Feat{
  color:var(--highlight-color);
}
.Stat.PopOut{
  position: absolute;
  visibility: hidden;
  background-color: var(--color-brown-mid);
  border: solid 2px var(--color-brown-dmid);
  color: var(--text-statsheet-color);
  margin-left: -0.5vw;
  /* width: 80%; */
  /* float: left; */
}
.Stat:hover .PopOut{
  visibility: visible;
}
.Stat.SelectStat{
  cursor: pointer;
}
.Stat.SelectStat:hover{
  color:var(--highlight-color);
}
.ActName{
  color: var(--color-yellow-bold);
  /* efc38f */
  font-weight: bold;
}
.ActName.Inactive{
  color: var(--action-inactive-color);
}
.ActName.Involuntary{
  color: var(--action-invol-color);
}
.ActName.Reaction{
  color: var(--highlight-color);
}
.ActName.Bonus{
  color: yellow
}
.ActName.Legendary{
  color: var(--color-purple);
}
/* .ActDesc{
  color: var(--color-beige);
  margin-left: 0.25vw;
} */

.BLog{
  width: 27.56vw;
  height: 28.56vh;
  left: 23.63vw;
  top: 62.78vh;
  
  scrollbar-width: none;
  -ms-overflow-style: none;;
  /* background-color: var(--color-brown-mid); */
  padding-left:2vw;
  
}
.BLog2{
  position:fixed;
  width: 28.06vw;
  height: calc(28.56vh - .5vw);
  left: 23.38vw;
  top: calc(62.78vh + .25vw);
  background-color: var(--color-brown-mid);
  z-index: 1;
}
.BLog3{
  position:fixed;
  width: 27.56vw;
  height: 28.56vh;
  left: 23.63vw;
  top: 62.78vh;
  background-color: var(--color-brown-mid);
  z-index: 1;
}

.BLogFilters{
  position: fixed;
  left: 22.19vw;
  top: 92.8vh;
  width: 30.935vw;
  height: 3vh;
  color: var(--color-brown-dmid2);
  font-size: 80%;
  /* background-color: red; */
  padding: 0.7vh 1.2vw;
}

.BLFText{
  float:left;
  font-weight: bold;
}

.BLFilter{
  /* background-color: red; */
  float:left;
  margin-left: 0.5vw;
  color: var(--color-beige);
  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/GBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw;
  width: 4vw;
  text-align: center;
  height: 2.25vh;
  cursor: pointer;
  /* padding-bottom: 5.8vh; */
}
.BLFilter.Selected{
  /* background-color: blue; */
  float:left;
  margin-left: 0.5vw;
  border-image-source: url('/Art/UI/BBorder.png');
  color: #eddb3c;
}
.BLFilter:hover{
  border-image-source: url('/Art/UI/DGBorder.png');
}

.BLFT{
  position: relative;
  bottom: 0.25vh;
  cursor: pointer;
}

.BLBorder{
  position: fixed;
  left: 22.19vw;
  top: calc(58.05vh - 0.1875vw);
  width: 30.935vw;
  height: calc(38.12vh + 0.375vw);
  border: 0.23vw var(--color-brown-light) solid;
  /* z-index: -5; */
}
.BLBorder2{
  position: fixed;
  left: 22.19vw;
  top: 61.8vh;
  width: 30.935vw;
  height: 30.71vh;
  border: 0.23vw var(--color-brown-light) solid;
  /* z-index: 1; */
}

.BorderGap{
  width: .75vw; 
  top: 57vh;
  height: 50vh;
  background-color: transparent;
  border: 0.3vw var(--battle-color) solid;
  position: fixed;
  /* z-index: 5; */
}
.BorderGap.BLL{
  left: 23.38vw;
}
.BorderGap.BLR{
  left: 51.2vw;
}
.BorderGap.CLL{
  left: 55.47vw;
}
.BorderGap.CLR{
  left: 75.95vw;
}

.LogTop{
  position: fixed;
  width: 2.6875vw;
}
.LogTopI{
  position: fixed;
  width: 1.4vw;
}
.LogBot{
  position: fixed;
  width: 1.75vw;
  z-index: 2;
}

.ChatWindow{
  position: fixed;
  width: 20vw;
  height: 28.56vh;
  left: 55.22vw;
  top: 62.78vh;
  
  scrollbar-width: none;
  -ms-overflow-style: none;;
  /* background-color: var(--color-brown-mid); */
  padding-top: 1.75vh;
  scroll-margin: 200px;

  /* box-sizing: border-box; */
  /* border: 0.2vw red solid; */
  /* border-image-source: url('/Art/UI/BBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw; */
  /* overflow-y: hidden; */
  
  /* padding:0 2vw; */
}

.CWBG{
  position: fixed;
  width: 20vw;
  height: 28.56vh;
  left: 55.22vw;
  top: 62.78vh;
  

  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/BBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw;

  z-index: 1;
}

.CIPort{
  position: absolute;
  width:8vw;
  height:8vw;
  transform-Origin: 54% 87.5%;
  z-index: 2;
}
/* .CIPort.Left{ */
  /* background-color: blue; */
  /* transform: scaleX(-1); */
  /* left:-5vw; */
/* } */

.CIPortC{
  position: absolute;
  width:2vw;
  height:2vw;
  /* transform-Origin: 54% 87.5%; */
  z-index: 2;
  /* border: 1px black solid; */
  border-radius: 1vw;
  /* background-color: blue; */
}
.CIPortC.Left{
  transform: scaleX(-1);
}


.CWInner{
  position: relative;
  border-left: #efc38f dashed 1px;
  border-right: #efc38f dashed 1px;
  width:calc(100% - 1vw);
  height:108%;
  top:-5%;
  margin:auto;
  padding: 0vw 1.25vw;
  z-index: 1;
  /* display: flex;
  justify-content: flex-end;
  flex-direction: column; */
}

.CWBorder{
  position: fixed;
  left: 54.44vw;
  top: calc(58.05vh - 0.1875vw);
  width: 23.4375vw;
  height: calc(38.12vh + 0.375vw);
  border: 0.23vw var(--color-brown-light) solid;
  z-index: -1;
}
.CWBorder2{
  position: fixed;
  left: 54.44vw;
  top: 61.8vh;
  width: 23.4375vw;
  height: 30.71vh;
  border: 0.23vw var(--color-brown-light) solid;
  z-index: -1;
}

.ChatInput{
  position: fixed;
  left: 56.19vw;
  top: 93.33vh;
  width: 15.69vw;
  height: 1.89vh;
  color: var(--text-statsheet-color);
  border:transparent;
  background-color: transparent;
}

.CIBG1{
  position: fixed;
  left: 56.125vw;
  top: 93vh;
  width: 15.81vw;
  height: 2.56vh;
  background-color: var(--color-green-mid2);
}
.CIBG2{
  position: fixed;
  left: 56vw;
  top: 93.22vh;
  width: 16.06vw;
  height: 2.11vh;
  background-color: var(--color-green-mid2);
}

.CIBG3{
  position: fixed;
  left: 56.1875vw;
  top: 93.15vh;
  width: 15.67vw;
  height: 2.30vh;
  background-color: #4e4134;
}

.CIBG4{
  position: fixed;
  left: 56.0625vw;
  top: 93.38vh;
  width: 15.9375vw;
  height: 1.85vh;
  background-color: #4e4134;
}

.BetInputBox{
  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/GBBorder.png');
  border-image-slice: 3 3 3 3 fill;
  border-image-width: 0.5vw;
}

/* .BetInputBox.Active{
  border-image-source: url('/Art/UI/YBBorder.png');
} */

.BetInputBox.Left{
  position: fixed;
  left: 7.55vw;
  top: calc(73.67vh - .2vw);
  width: 6.9vw;
  height: calc(4.556vh + .4vw);
  /* left: 7.75vw;
  top: 73.67vh;
  width: 6.5vw;
  height: 4.556vh; */
  /* background-color: var(--color-green-mid2); */
}

/* .BIBG.Left{
  position: fixed;
  left: 7.55vw;
  top: calc(73.67vh - .2vw);
  width: 6.9vw;
  height: calc(4.556vh + .4vw);
  background-color: #4e4134;
} */

.BetInputBox.Right{
  position: fixed;
  left: 85.6125vw;
  top: calc(73.67vh - .2vw);
  width: 6.9vw;
  height: calc(4.556vh + .4vw);
  /* left: 85.8125vw;
  top: 73.67vh;
  width: 6.5vw;
  height: 4.556vh; */
  /* background-color: var(--color-green-mid2); */
}

/* .BIBG.Right{
  position: fixed;
  left: 85.6125vw;
  top: calc(73.67vh - .2vw);
  width: 6.9vw;
  height: calc(4.556vh + .4vw);
  background-color: #4e4134;
} */

.BetInput{
  margin: auto;
  width: 100%;
  background-color: transparent;
  border: 0;
  color: var(--text-statsheet-color);
  font-size: 120%;
  text-align: center;
  margin-top: 1.1vh;
}

.YourBet{
  /* margin: auto; */
  margin-left: -0.08vw;
  width: 100%;
  background-color: transparent;
  border: 0;
  color: var(--text-statsheet-color);
  font-size: 120%;
  text-align: center;
  margin-top: 0.85vh;
  /* color: #677e52 */
}

.SendButton{
  position: fixed;
  left: 72.31vw;
  top: 93vh;
  width: 3.625vw;
  height: 2.556vh;
  border:transparent;

  text-align: center;
  cursor: pointer;
  padding-top: 0.1vw;
  color: var(--text-cream-color);
  font-size: 80%;
}

.SendButton:hover{
  background-color: rgba(0,0,0,0.2);
}
.SendButton.Invalid{
  color: var(--text-rust-color);
}

.BIEA{
  position: absolute;
  font-size: 80%;
  top: 5.5vh;
  width:100%;
  text-align: center;
  color: #4e4134;
}

.SBBG1{
  position: fixed;
  left: 72.435vw;
  top: 93vh;
  width: 3.35vw;
  height: 2.56vh;
  background-color: var(--color-green-mid2);
}

.SBBG2{
  position: fixed;
  left: 72.31vw;
  top: calc(93vh + 0.125vw);
  width: 3.625vw;
  height: calc(2.556vh - 0.25vw);
  background-color: var(--color-green-mid2);
}

.SBBG3{
  position: fixed;
  left: 72.5vw;
  top: calc(93vh + 0.094vw);
  width: 3.175vw;
  height: calc(2.556vh - 0.1875vw);
  background-color: #4e4134;
}

.SBBG4{
  position: fixed;
  left: 72.4vw;
  top: 93.38vh;
  width: 3.425vw;
  height: 1.85vh;
  background-color: #4e4134;
}

.BWindow{
  /* left: 20.56vw;
  top: 17.44vh;
  width: 58.94vw;
  height: 37vh; */
  overflow: hidden;
  background-size: cover;
  background-position: center bottom;
  color:black;
}

.BWBorder{
  position: fixed;
  /* left: 19.82vw;
  top: calc(17.44vh - 0.688vw);
  width: 60.42vw;
  height: calc(37vh + 1.41vw); */
  border: 0.5vw var(--color-brown-light) solid;
}

.BWCorner{
  position: fixed;
  width: 2.875vw;
}
/* .BWCorner.Temp{
  left: calc(20.56vw - (2.875vw / 2) - 0.0vw);
  top: calc(17.44vh - (2.875vw / 2) - 0.0vw);
  
} */

.BetsStatus{
  left: 36.96vw;
  top: 13.45vh;
  width: 26.14vw;
  height: 4vh;
  position: absolute;
  font-size: 100%;
  padding-top: calc((4vh - var(--font-size)) / 2);
  font-weight: bold;
  z-index: 1;

  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/BetBorder.png');
  border-image-slice: 9 9 9 9 fill;
  border-image-width: 0.5vw;
}

.BarMid{
  width:calc((100% - var(--bet-status-margin-size)) / 3);
  text-align: center;
  position: absolute;
  left:8.75vw;
  top:2.9vw;
  font-size: 50%;
  /* float:left; */
  /* margin:auto; */
  /* background-color: red; */
  z-index: 5;
}
.OddsMid{
  position: fixed;
  top:calc(15.95vh + 1.5vw);
  left: 48vw;
  width:4vw;
  /* background-color: red; */
}
.BarLeft{
  /* width:calc((100% - var(--bet-status-margin-size)) / 3); */
  text-align: center;
  /* text-anchor: middle; */
  float:left;
  /* margin:auto; */
}
.BarRight{
  /* width:calc((100% - var(--bet-status-margin-size)) / 3); */
  text-align: center;
  float:right;
  margin-right: var(--bet-status-margin-size);
  margin:auto;
}

.BetAvatar{
  left: 47.94vw;
  top: calc(15.95vh - 2.45vw);
  width: 4vw;
  height: 4.875vw;
  position: fixed;
  z-index: -2;
}
.BetAvatar.BG{
  z-index: -3;
}
.BetAvatar.FG{
  z-index: -1;
}
.BetAvatar.MonFace{
  position: relative;
  left:0.05vw;
  top:0.05vw;
  width: 3.75vw;
  height: 3.75vw;
  /* height: 20vw;
  width: 20vw; */
  /* background-color: red; */
  /* z-index: 100; */
}
.BetAvatar.MonFace.Left{
  transform: scaleX(-1);
}

.Dots{
  height:.875vw;
  width: .625vw;
  top:14.56vh;
  position: fixed;
}
.Dots.Left{
  /* position: relative; */
  left: 46.63vw;
  /* float:left; */
}
.Dots.Right{
  /* position: relative; */
  left: 52.63vw;
  transform: scaleX(-1);
}
.BetsName{
  text-align: right;
  /* width: 100%; */
  /* background-color: red; */
  position: absolute;
  right:16.6vw;
  top:0.1vh;
  /* width:20vw; */

}
.BetsName.Long{
  font-size: 80%;
  top:0.45vh;
}
.BetsScore{
  position: absolute;
  left:18.1vw;
  top:0.25vh;
}
.BetsCoin{
  position: fixed;
  top:14.56vh;
  left: 54.13vw;
  width: .95vw;
}

.BetBranch{
  position: fixed;
  top: 14.22vh;
  width: 3.5vw;
  height: 2.67vh;
  /* z-index: -0.5; */
}
.BetBranch.Left{
  left: 34.625vw;
}
.BetBranch.Right{
  left: 61.938vw;
  transform: scaleX(-1);
}

.BetHands{
  position: fixed;
  height: 6.444vh;
}

.QuickBet{
  position: absolute;
  top: 10vh;
  width:4.5vw;
  text-align: center;
  font-size: 80%;
  cursor: pointer;
  
  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/GBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw;
}
.QuickBet.One{
  left: -1.75vw;
}
.QuickBet.Two{
  left: 3.75vw;
}
.QuickBet.Three{
  left: -1.75vw;
  top: 15vh;
}
.QuickBet.Four{
  left: 3.75vw;
  top: 15vh;
}
.QuickBet:hover{
  border-image-source: url('/Art/UI/DGBorder.png');
}


.Banner{
  position: absolute;
  background-color: var(--color-brown-light);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: var(--text-cream-color);
  font-weight: bold;
  font-size: 80%;
}

.HeadTextHolder{
  font-size: 50%;
  /* font-family: var(--ht-font); */
  color: var(--text-statsheet-color);
  font-weight: bold;
  -webkit-text-stroke-color: var(--window-color);
  /* margin:auto; */
  z-index: 3;
  position: relative;
  top:11vh;
  height: 3vh;
  width: 20vw;
  left: 3.5vw;
  /* background-color: red; */
  text-align: left;
}
.HeadTextHolder.Right{
  left: 1vw;
}

.HTHH{
  margin:auto;
  /* background-color: aqua; */
  z-index: 30;
  height: 2vh;
  min-width:1vw;
}

.HTSmallDice{
  float:left;
  /* float: none; */
  /* background-color: yellow; */
  /* width:15vw; */
}

.HTSmallDice.SubDice{
  position: absolute;
  top: 3.5vh;
  color:var(--text-statsheet-color);
  /* margin: auto; */
  /* width:100%; */
  width: 7vw;
  /* background-color: red; */
  /* text-align: center; */
}

.HTSmallDice.SubDice.Indent{
  left: 2vw;
}

.HTI{
  float: none;
  /* float: left; */
}

.MIMGTARGET{
  position: fixed;
  left: 58.75vw;
  top: 33.33vh;
  width: 9.69vw;
  height: 3vh;
  /* background-color: aqua; */
  z-index: 1;
}
.MIMGTARGET.Left{
  left: 30.56vw;
}

.BattleDie{
  float:left;
}

.LogDie{
  float:left;
}

.BattleSub{
  float:left;
  /* height:5vw; */
}

.DistHolder{
  color: var(--text-statsheet-color);
  font-size: 50%;
  /* position: inline; */
}

.DistBox{
  /* position: inline; */
  background-color: var(--text-statsheet-color);
  color: var(--color-brown-light);
  width:3.5vw;
  height:0.5vw;
  padding:0vw .3vw 0vw .3vw;
}

.DistDot{
  position: relative;
  bottom: .15vw;
}

.WelcomeHeader{
  margin: auto;
  position: absolute;
  left: calc((100% - 42.33vh) / 2);
  top:0%;
  background-image: url('/Art/UI/TopbarHeader.png');
  width: 42.33vh;
  height: 8.78vh;
  z-index: 1;
  background-size: cover;
}

.WelcomeTitle{
  margin: auto;
  position: absolute;
  left: calc((100% - 21.22vh) / 2);
  top:1.67vh;
  background-image: url('/Art/UI/TopbarTitle.png');
  width: 21.22vh;
  height: 3.22vh;
  z-index: 2;
  background-size: cover;
}

.WelcomeText{
  width: 50%;
  text-align: center;
  margin: auto;
}

.WelcomeText.Big{
  font-size: 120%;
  color: var(--text-rust-color);
}

.WelcomeText.Green{
  color: var(--color-green-dark);
}

.WelcomeWindow{
  z-index: 125;
  position: fixed;
  width: 70%;
  min-height: 70%;
  left: 15%;
  bottom: 10vh;
  padding: calc(56px + 0vw);
  padding-top: calc(56px + 5vh);
  font-size: 100%;
  border: 1vw solid black;
  box-sizing: border-box;
  border-image-source: url('/Art/UI/WelcomeBorder.png');
  border-image-slice: 33.3% fill;
  border-image-width: 111px 111px;
  color: var(--color-brown-dark);
  font-weight: bold;
}


.Banner.Welcome{
  background-color: var(--color-brown-dark);
  color: var(--battle-color);
}

.WelcomeBannerHolder{
  width: 6vw;
  height: 1vw;
  margin: auto;
  /* background-color: red; */
}

.RandomStat{
  border: 1px var(--color-brown-light) solid;
  width: 26%;
  margin: auto;
  position: relative;
  left: 8%;
  padding-left: .4vw;
}

.WelcomeRerollDie{
  width:2vw;
  height:2vw;
  position:fixed;
  left:40.25%;
  top:46.5vh;
  cursor: pointer;
}

.WelcomeGold{
  position: fixed;
  left: 39.65%;
  top: 51.25vh;
  /* background-color: red; */
  text-align: center;
}

/* .WelcomeStart{
  position: fixed;
  left: 25%;
  bottom: calc(13vh - 1.5vw);
  width: 10vw;
  cursor: pointer;
} */
.WelcomeStart{
  position: fixed;
  left: 45%;
  bottom: calc(13vh - 1.5vw);
  width: 10vw;
  height: 3.38vw;
  cursor: pointer;
  color: var(--battle-color);
  background-image: url('/Art/UI/StartButton.png');
  background-size: cover;
  text-align: center;
  padding-top: 0.85vw;
  font-size: 125%;
}
.WelcomeStart.Left{
  left: 30%;
}
.WelcomeStart.Right{
  left: 60%;
}
.WelcomeStart.SLeft{
  left: 37.5%;
}
.WelcomeStart.SRight{
  left: 52.5%;
}

.WWIcon{
  position: fixed;
  top: 65vh;
  width: 2.6875vw;
  height: 2.5vw;
  z-index: 2;
}
.WWIcon.Left{
  left: 42vw;
}
.WWIcon.Right{
  right:42vw;
}

.WWIconText{
  position: fixed;
  /* background-color: rgba(0,0,0,0.2); */
  top: 65vh;
  width: 12.6875vw;
  /* height: 5vw; */
  text-align: center;
  color: #6f5944;
  font-size: 80%;
  padding-top:5vh;
  cursor: pointer;
  z-index: 3;
}
.WWIconText.Left{
  left: 37vw;
}
.WWIconText.Right{
  right: 37vw;
}
.Selector{
  padding-top:0.5vw;
}
.WWIconText:hover{
  color: #3f2924;
}
.WelcomeDungeon{
  position: absolute;
  left: 30%;
  bottom: 13%;
  width: 40%;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost-container {
  position: relative;
  height: 100%;
  /* overflow: hidden; */
}
.scrollhost {
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.scroll-bar {
  width: .4vw;
  height: 100%;
  right: -4vw;
  top: 0px;
  position: absolute;
  bottom: 0px;
  background-color: var(--color-brown-light);
}

.scroll-thumb {
  width: .6vw;
  height: 10%;
  margin-left: -.09vw;
  position: absolute;
  top: 0;
  background-color: var(--color-brown-dmid);
}


::-webkit-scrollbar {
  /* display: none; */
  background-color: var(--color-brown-light);
  width: .4vw;
}

::-webkit-scrollbar-thumb{
  background-color: var(--color-brown-dmid);
  width: .6vw;
  margin-left: -.09vw;
}

.BLogScroll{
  /* width: 101%; */
  padding-left: 2vw;
  margin-left: -2vw;
  padding-right: 1vw;
  margin-right: -1vw;
  height:100%;
  z-index: 5;

  color:#c9b49f;
  font-size: 80%;
  
  /* background-color: red; */
}

.CWScroll{
  /* width: */
  padding-left: 3vw;
  margin-left: -3vw;
  padding-right: 3vw;
  margin-right: -3vw;


  /* display: flex;
  justify-content: flex-end;
  flex-direction: column; */
}

/* .scrollhost.CWScroll{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
} */

.SSScroll{
  /* width: 101%; */
  padding-left: 2vw;
  margin-left: -2vw;
  padding-right: 1vw;
  margin-right: -1vw;
  height:100%;
  /* width: 210%; */
  
  /* background-color: red; */
}

.SSStats{
  height: calc(50.11vh - 5vw);
  width: calc(85% + 2vw);
  position: absolute;
  top: 5vw;
  overflow: auto;
  padding-left: 2vw;
  margin-left: -2vw;
  
  /* background-color: blue; */
}

.SSStats.Swarm{
  top: 6vw;
  height: calc(50.11vh - 6vw);
}

.SSStats.ProfilePage{
  height: calc(75.11vh - 7.0vw);
  top: 7.0vw;
}

.HPHeart{
  width:1.38vw;
}

.Coin{
  width: .95vw;
}

.LoginWindow{
  position: absolute;
  left: 10vw;
  width: 20vw;
  top: 2vh;
  height: 7vh;
  padding: 0.5vw;
  text-align: center;
  /* background-color: var(--color-green-mid2); */
  /* border: .2vw solid var(--color-brown-dmid2); */
  /* border-radius: 1vw; */

  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/GBBorder.png');
  border-image-slice: 3 3 3 3 fill;
  border-image-width: 0.5vw;
}

.LoginButton{
  width: 5vw;
  height: 3vh;
  text-align: center;
  margin: auto;
  cursor: pointer;
  z-index: 200;
  margin-top: 0.5vw;

  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/BBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw;
}
.LoginButton.Prompt{
  float: left;
  width: 6.25vw;
  margin-left: 2.4vw; 
  margin-top: -.5vh;
  /* color: blue; */
}
.LoginInfoMsg{
  float: left;
  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/GBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw;
  position: relative;
  top: -0.65vh;
  left: 0.8vw;
  padding: 0 0.5vw;
}
.FakeLink{
  text-decoration: underline;
  color: #3f83d0;
  cursor: pointer;
}
.FakeLink.Small{
  font-size: 75%;
  font-style: italic;
}
.LPText{
  float: left;
  /* color: red; */
}

.LoginPrompt{
  position: fixed;
  left: 56.19vw;
  top: 93.33vh;
  width: 25.69vw;
  height: 1.89vh;
  color: var(--text-statsheet-color);
  border:transparent;
  background-color: transparent;
}

.LoginInfo{
  margin: auto;
  margin-top: 5vh;
  text-align: center;
}

.LoginError{
  color: red;
  font-style: italic;
}

.SettingsHeader{
  color: #1f5390;
  font-size: 110%;
  font-weight: bold;
}

.SettingsButton{
  color: var(--color-beige);
  box-sizing: border-box;
  border: 0.2vw red solid;
  border-image-source: url('/Art/UI/GBorder.png');
  border-image-slice: 2 2 2 2 fill;
  border-image-width: 0.5vw;
  width: 10vw;
  margin: auto;
  cursor: pointer;
}

.LoginInput{
  width: 15vw;
}

.LoginSubMsg{
  font-size: 80%;
  width: 15vw;
  text-align: right;
  margin: auto;
  cursor: pointer;
}

.HiddenAudio{
  visibility: hidden;
  position: absolute;
}

.LBTabsHolder{
  /* background-color: lightblue; */
  width: 100%;
  text-align: center;
  /* position: absolute; */
  /* top: 8vh; */
  /* left: 0; */
  margin-top: -2vh;
}

.LBTab{
  width: 7vw;
  /* background-color: lawngreen; */
  height: 5vh;
  display: inline-table;
  margin: 0.5vw 1vw;
  border: 0.2vw red solid;
  border-image-slice: 3 3 3 3 fill;
  border-image-width: 0.5vw;
  
  border-image-source: url('/Art/UI/GBBorder.png');
  cursor: pointer;
  /* position: initial; */
  /* float: left; */
}


.LBTab.Active{
  /* background-color: red; */
  border-image-source: url('/Art/UI/YBBorder.png');
  color: var(--topbar-border-color);
}

.LBTab:hover{
  border-image-source: url('/Art/UI/BBBorder.png');
  color: var(--color-green-dark);
}

.ProfileHolder{
  background-color: var(--battle-color);
  width:100vw;
  height:100vh;
  position: absolute;
}

.Profile{
  color: black;
  border: 0.2vw red solid;
  border-image-slice: 3 3 3 3 fill;
  border-image-width: 0.5vw;
  border-image-source: url('/Art/UI/GBBorder.png');
  margin:3vw 10vw 0 10vw;
  padding: 1vw;
  min-height: 88vh;
}

.ProfStat{
  margin: 3px;
}

.ProfLink{
  text-decoration: none;
  /* color: red; */
}

a.ProfLink{
  color:inherit;
}

a.ProfLink:hover{
  color:var(--highlight-color);
  font-weight: bold;
}

a.ProfLink.Light:hover{
  color:#386664;
}

.BuyOptionHolder{
  /* background-color: red; */
  position: absolute;
  top: 71.111vh;
  width:13.625vw;
  left: 4.1875vw;
  height:25vh;

  font-size: 1.3vh;
  text-align: center;
  color:var(--battle-color);
  font-weight: bold;
  /* height:2.778vh; */
}

.BuyOptionHolder.Right{
  /* background-color: blue; */
  left: 82.25vw;
}

.BuyOption{
  border: 0.2vw red solid;
  border-image-width: 0.5vw;
  border-image-slice: 3 3 3 3 fill;
  border-image-source: url('/Art/UI/LBGBorder.png');
  /* border-image-slice: 2 2 2 2 fill;
  border-image-source: url('/Art/UI/BBorder.png'); */
  margin-bottom: 2vh;
  height:4.5vh;
  cursor: pointer;
}


.BuyOption.Invalid{
  cursor: auto;
  border-image-slice: 2 2 2 2 fill;
  border-image-source: url('/Art/UI/BBorder.png');
}

.BuyOption:hover{
  border-image-slice: 3 3 3 3 fill;
  border-image-source: url('/Art/UI/BGBorder.png');
}


.BuyOption.Static{
  cursor: auto;
  border-image-slice: 2 2 2 2 fill;
  border-image-source: url('/Art/UI/BBorder.png');
}


.ConfirmWindow{
  z-index: 125;
  position: fixed;
  width: 50%;
  height: 30vh;
  left: 25%;
  bottom: 25vh;
  padding: 2vh 2.5vw;
  /* padding-top: calc(56px + 5vh); */
  font-size: min(1.76vh,1.1vw);
  border: 1vw solid black;
  box-sizing: border-box;
  /* border-image-source: url('/Art/UI/WelcomeBorder.png');
  border-image-slice: 33.3% fill; */
  border-image-width: 2vw;
  border-image-slice: 3 3 3 3 fill;
  border-image-source: url('/Art/UI/BGBorder.png');
  /* border-image-width: 111px 111px; */
  color: var(--text-statsheet-color);
  font-weight: bold;
}
.WelcomeStart.Confirmer{
  bottom: calc(25vh - 1.3vw);
}

.Statsheet.PCProfile{
  font-size: 500%;
}

.TossItem{
  position: absolute;
  /* left: 20%; */
  top: -5vw;
  width: 5vw;
  height: 5vw;

  animation: tossFall 2.5s;
  animation-timing-function: ease-in-out;
  z-index: 10;
  /* animation-duration: 2s; */
  /* background-color: red; */
}
/* .TossItem.Right{
  left: 80%;
} */

@keyframes tossFall {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  100% { transform: translate(0,48vh) rotate(720deg) scaleX(1); }
}

.TossCheer{
  z-index: 9;
  font-family: Comic Sans MS;
  position: absolute;
  text-align: center;
  padding:0.2vw 0.4vw;
  top: 1vh;
  /* width: 5vw; */
  background-color: ivory;
  border: 1px black solid;
  border-radius: 1vw;
  
  animation: shake 1s infinite;
}

.ChatCheer{
  width: 2vw;
  animation: spin 1s infinite linear;
}

.MProfileArt{
  /* position: absolute; */
  /* left: calc(45vw);
  top: calc(50vh - 35vmin); */
  width: 70vmin;
  height: 70vmin;
  transform-origin: 54% 87.5%;
  /* background-color: red; */
}
.MProfileArt.Dead{
  transform: rotate(90deg);
}

.MProfileArtHolder{
  position: fixed;
  left: calc(45vw);
  top: calc(50vh - 35vmin);
  width: 70vmin;
  height: 70vmin;
  /* background-color: blue; */
}

.ShadowProfile{
  position: absolute;
  /* left: calc(45vw);
  top: calc(50vh - 35vmin); */
  /* width: 5vmin; */
  
  left:27.5vmin;
  bottom: 6.7vmin;
  width: 20vmin;
  z-index: -5;
  /* height: 70vmin; */
  /* background-color: green; */
}

.PiercedResist{
  color: lightgray;
}

.Portrait.ProcBody{
  /* background-color: red; */
  overflow:hidden;
}

.ProcPart{
  position: absolute;
  /* width: 5vmin;
  height: 20vmin; */
  /* left:12vmin;
  bottom:0.1vmin; */
  z-index: -5;
  /* background-color: green; */
}

.ProcBeard{
  z-index: -3;
}
.ProcHead{
  z-index: -5;
}
.ProcERear{
  z-index: -8;
}
.ProcETorso{
  z-index: -6;
}
.ProcTorso{
  z-index: -7;
}
.ProcHorns{
  z-index: -2;
}

/* .ProcHead{
  filter: saturate(20%) brightness(100%) hue-rotate(280deg);
}
.ProcTorso{
  filter: saturate(20%) brightness(100%) hue-rotate(280deg);
}
.ProcLegs{
  filter: saturate(20%) brightness(100%) hue-rotate(280deg);
}
.ProcHair{
  filter: saturate(100%) brightness(100%) hue-rotate(280deg);
} */