:root {
  

  --font-size: 1.1vw;
  --margin-size: 1.4vw;
  --padding-size: 0.7vw;
  --small-pad-size: 0.2vw;
  --border-size: 0.05vw;
  --ss-height: calc(100% - (var(--margin-size) * 2) - var(--topbar-height));
  --bw-height: 40%;
  --bw-width: calc(0.6 * (100% - (var(--margin-size) * 4)));
  /* --monster-size: max(34.25vh,10vw); */
  --monster-size: 34.25vmin;
  --bet-status-margin-size: 1.5vw;
  --button-size: 12vw;
  --shake-size: .1vw;
  --die-size: 2.5vw;
  --die-log-size: 0.7;
  --attack-size: 20vw;
  --text-outline-size:1.0px;
  --text-outline-size-m:-1.0px;
  --text-outline-blur:0;

  --topbar-color: #DB8584;
  --topbar-border-color: #333333;
  /* --battle-color: #84DB9F; */
  --window-color: #B3FFCB;
  
  --window-border-color: #478F5E;
  --topbar-text-color: black;
  --window-text-color: black;
  --teamless-border-color: #333333;
  --team-none-color: #848584;
  --team-red-border-color: #8F3B39;
  --team-red-color: #DB8584;
  --team-red-border-color: #393B8F;
  --team-blue-color: #8485DB;
  

  --color-green-dark: #677e52;
  --color-green-mid: #758c60;
  --color-green-mid2: #728c5b;
  --battle-color: #aaa98c;
  --text-statsheet-color: #efc38f;
  --highlight-color: #7fd3d0;
  --text-cream-color: #cec293;
  --text-rust-color: #a15202;
  --color-brown-dark: #3a241e;
  --color-brown-dmid: #483a2d;
  --color-brown-dmid2: #594a3c;
  --color-brown-mid: #675645;
  --color-brown-light:#695544;
  --color-beige: #dddbb6;

  --hp-bg-color: #301e19;
  
  --color-yellow-bold: #ecc53e;
  --color-purple: rgb(228, 100, 228);
  --action-inactive-color: #c9c9c9;
  --action-invol-color: #dddddd;
  /* --color-purple: #6e54e2; */
  /* #eecb66 */
  /* --text-egg-color: #aaa98c; */

  --main-font: "Times New Roman", Times, serif;
  /* --main-font: "Comic Sans MS", "PT Serif", serif; */
  --ht-font: "Comic Sans MS", cursive, sans-serif;

  /* Old Colors */
  /* --border-size: 0.05vw;
  --topbar-color: #DB8584;
  --topbar-border-color: #333333;
  --battle-color: #84DB9F;
  --window-color: #B3FFCB; */
  /* --team-none-color: #848584;
  --team-red-border-color: #8F3B39;
  --team-red-color: #DB8584;
  --team-red-border-color: #393B8F;
  --team-blue-color: #8485DB; */

  /* "Times New Roman", Times, serif; */
  /* "Comic Sans MS", cursive, sans-serif; */
  /* "Arial Black", Gadget, sans-serif; */
  /* #; */
  /* #BEAC76 #CEC4C5 #422336 #7F546D #889C86 */
  /* #75ff88 #6e57ff #ff6b56 #a99bff #ffa79a */

}

* {
  box-sizing: border-box;
}


html,body {
  font: caption;
  font-family: var(--main-font);
  animation-duration: 0.2s;
  font-size: var(--font-size);
  overflow: hidden; 
  /* white-space: pre-line; */
  text-decoration-color: #11111155;
  margin: 0;
  padding: 0;
  block-size: auto;
}


.App{
  width: 100vw;
  height: 100vh;
  margin: 0vw;
}



.TopBarTitle{
  font-size: 100%;
  vertical-align: middle;
  margin-left: 1%; 
}

.TopBarName{
  font-size: 80%;
  margin-top: 0.5vw;
  margin-right: 3vw;
  /* height: 100%; */
  vertical-align: middle;
  margin-left: 1%;
  float: right; 
}


.BattleScreen{
  width: 100vw;
  height: calc(100vh - var(--topbar-height));
  background-color: var(--battle-color);
  float: left;
}

.Block{
  /* border: var(--border-size) solid var(--teamless-border-color);
  background-color: var(--window-color);
  color: var(--window-text-color); */
  padding: var(--padding-size);
  position: absolute;
  /* border-radius: var(--border-size); */
}



/* Not in use */
.LastLog{
  position: absolute;
  left: 0%;
  bottom: 2%;
  width: 100%;
  min-height: var(--padding-size);
  /* background-color: red; */
  text-align: center;
  font-size: 175%;
}



.Block.Left{
  left: var(--margin-size);
}.Block.Right{
  right: var(--margin-size);
}
.Stat{
  width: 33%;
  /* max-height:1vw;
  background-color: red; */
  float: left;
  text-indent:-0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  overflow:visible;
  white-space: nowrap;
  /* white-space:inherit; */
}
.Stat.Small{
  overflow:hidden;
  min-width: 33%;
  width: auto;
  white-space: normal;
}
.Stat.Big{
  width: 67%;
  font-size: 200%;
  text-decoration: underline;
  /* margin-bottom: var(--font-size); */
}
.Stat.SemiWide{
  width:67%;
}
.Stat.Wide{
  width: 100%;
  white-space: normal;
}
.Stat.Mod{
  width: 100%;
  white-space: normal;
}
.Stat.Italic{
  /* width: 100%; */
  font-style: italic;
  /* margin-bottom: var(--font-size); */
}
/* .Stat.Mod{
  min-width: 100%;
} */



.LogLine{
  width: calc(100% + 1.25vw);
  margin: 0vw -1vw 0vw -1vw;
  padding: 0vw .25vw 0vw .25vw;
  float: left;
  min-height:calc(var(--die-size) * var(--die-log-size));
}

.LogLine.Odd{
  background-color: rgba(0,0,0,0.1);
}


.LogLine.Big{
  /* font-size: 125%; */
  /* background-color: rgba(0,0,0,0.2); */
  /* margin-top: -1.55vh; */
  /* padding-top: 1.55vh; */
  margin-left:-1.5vw;
  width: calc(100% + 2vw);
  color:#ecd37f;
  /* font-weight: bold; */
}
.LogLine.NewBattle{
  color:#f79509;
  font-weight: bold;
}

.LLPlus.Big{
  color:cyan;
  /* font-size: 10%; */
}

.LLBreak{
  float:left;
  height:0.1vw;
  width:calc(100% + 1.25vw);
  padding-left: .25vw;
  margin-left:-1vw;
  border-bottom: dashed #c9b49f 1px;
  margin-top:.1vw;
  margin-bottom: .1vw;
}

.LogText{
  float: left;
  max-width: 62%;
  padding-top:.25vw;
  /* background-color: red; */
  /* height:100%; */
  min-height: calc(var(--die-size) * var(--die-log-size));
}

.LogRoll{
  float: right;
  width: 38%;
  /* background-color: green; */
  height:100%;
  text-align: right;
  font-weight: normal;
  /*  */
}

.LogRollC{
  float: right;
}

.DieContainer{
  width: var(--die-size);
  height: var(--die-size);
  float: left;
  background-size: cover;
  text-align: center;
  padding-top: 0.45vw;
  /* -webkit-text-stroke-color: rgba(0,0,0,0); */
  font-weight: bold;

  /* background-color: black; */
  
  /* color: red;
  font-size:16px;
  -webkit-text-stroke: 1px black;
  paint-order: stroke fill; */
  /* text-shadow:
    var(--text-outline-size-m) var(--text-outline-size-m) var(--text-outline-blur) #000,
     0   var(--text-outline-size-m) var(--text-outline-blur) #000,
     var(--text-outline-size) var(--text-outline-size-m) var(--text-outline-blur) #000,
     var(--text-outline-size)  0   var(--text-outline-blur) #000,
     var(--text-outline-size)  var(--text-outline-size) var(--text-outline-blur) #000,
     0    var(--text-outline-size) var(--text-outline-blur) #000,
    var(--text-outline-size-m)  var(--text-outline-size) var(--text-outline-blur) #000,
    var(--text-outline-size-m)  0   var(--text-outline-blur) #000; */
  /* color:black; */
  /* font-family: var(--ht-font); */
  
}

.DieTextFG{
  /* content: attr(text); */
  position: relative;
  background: transparent;
  /* color:red; */
  z-index: 1;
  /* width:500px; */
  text-align: center;
  /* left:-0.5vw; */
  /* float:left; */
}
.DieTextFG:before{
  content: attr(text);
  position: absolute;
  -webkit-text-stroke: 3px black;
  left:0;
  z-index: -1;
  text-align: center;
}
.DieTextFG:after{
  content: attr(text);
  position: absolute;
  left:0;
  z-index: 1;
  text-align: center;
}

.Outline{
position: relative;
  background: transparent;
  z-index: 1;
  text-align: center;
}
.Outline:before{
  content: attr(text);
  position: absolute;
  -webkit-text-stroke: .1875vw black;
  left:0;
  z-index: -1;
  text-align: center;
}
.Outline:after{
  content: attr(text);
  position: absolute;
  left:0;
  z-index: 1;
  text-align: center;
}

.Outline.Bold{
  letter-spacing: 0.1vw;
}
.Outline.Bold:before{
  -webkit-text-stroke: .2vw black;
  text-shadow: black 0 0 .4vw;
}
/* 
  position: absolute;
  width:inherit;
  text-align:center;
  /* z-index: 2; */
  /* right: inherit; */
  /* right: 0vw; */
  /* float:left; */
  /* right: 0.5vw; */
  /* float:left; 
  
  left:calc(inherit - width);
  
}*/
.DieTextBG{
  position: absolute;
  -webkit-text-stroke: 3px black;
  /* background-color: red; */
  /* width:inherit; */
  /* text-align:center; */
  left:0;
  z-index: -1;
  /* right: inherit; */
  /* float:left; */
} 

.DieContainer.LogDie{
  width: calc(var(--die-size) * var(--die-log-size));
  height: calc(var(--die-size) * var(--die-log-size));
  font-size: calc( 115% * var(--die-log-size));
  padding-top: calc(0.45vw * var(--die-log-size));
  background-size: contain;
}

.DieContainer.D4{
  background-image: url('/Art/UI/d4.png');
  padding-top: 0.9vh;
  font-size: 90%;
}
.DieContainer.D6{
  background-image: url('/Art/UI/d6.png');
}
.DieContainer.D6.Success{
  background-image: url('/Art/UI/d6S.png');
}
.DieContainer.D6.Failure{
  background-image: url('/Art/UI/d6F.png');
}
.DieContainer.D8{
  background-image: url('/Art/UI/d8.png');
  font-size: 90%;
}
.DieContainer.D10{
  background-image: url('/Art/UI/d10.png');
  font-size: 85%;
}
.DieContainer.D12{
  background-image: url('/Art/UI/d12.png');
}
.DieContainer.D20{
  background-image: url('/Art/UI/d20.png');
  font-size: 95%;
}
.DieContainer.D20.Success{
  background-image: url('/Art/UI/d20S.png');
}
.DieContainer.D20.Failure{
  background-image: url('/Art/UI/d20F.png');
}
.DieContainer.D20.Target{
  background-image: url('/Art/UI/d20T.png');
}
.DieContainer.Unchosen{
  opacity: .5;
}

.DieContainer.D4.LogDie{
  padding-top: 0.7vh;
}
/* .DieContainer.Success{
  filter: sepia(100%) saturate(400%) brightness(80%) hue-rotate(50deg);
}
.DieContainer.Failure{
  filter: sepia(20%) saturate(300%) brightness(80%) hue-rotate(270deg);
} */




/* .DieImg{
  width:100%;
  height:100%;
}

.DieText{
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
} */

.LogSub{
  float: left;
  padding-top: 0.75vh;
  /* background-color: blue; */
  /* font-size: 400%; */
}





.MIcon{
  font-size: 350%;
  position: absolute;
  /* -webkit-text-stroke-width: 0.05vw;
  -webkit-text-stroke-color: rgba(0,0,0,0.5); */
  top: 5vh;
  /* top: calc(35% - var(--font-size)*2.5); */
  width: 42%;
  text-align: center;
  
  height: calc(100% - 5vh);
  /* background-color: red; */
  /* background-color: aqua; */
}
.MIcon.Left{
  left: 3%;
}
.MIcon.Right{
  right: 3%;
}

.MEffect{
  position: absolute;
  bottom: -30%;
  left: 44%;
  width: 10vmin;
  text-align: center;
  height: 10vmin;
  /* background-color: #FF000033; */
  z-index: 2;
  /* width: 30vw;
  height: 30vw; */
}
/* .MEffect.Left{
  left: 44%;
}
.MEffect.Right{
  right: 44%;
} */

.HeadText{
  
  /* position: absolute; */
  /* width: 100%; */
  /* top: 11vh; */
  /* width:5vw; */
  min-width: 1vw;
  z-index: 4;
  font-size: 50%;
  /* left:0vw; */
  margin:auto;
  width:fit-content;
}

.HeadText{
  
  min-width: 1vw;
  z-index: 4;
  font-size: 50%;
  margin:auto;
  width:fit-content;
}
.HeadText.Raised{
  position: relative;
  top: -3vh;
}

.HeadText.Damage{
  color: #e22d1b;
}
.HeadText.Healing{
  color: #51cf4b;
}
.HeadText.Action{
  color:#33c9e4;
}

.HTDice{
  font-size: var(--font-size);
  -webkit-text-stroke-width: 0;
  color: var(--text-statsheet-color);
  /* background-color: red; */
  position: relative;
  /* margin: auto; */
  display: inline-block;
  /* text-align: center; */
  /* width: 100%; */

  top: 6vh;
  z-index: 4;
  height: 2.5vw;
  visibility: hidden;
  
}

.MName{
  width: 25vw;
  text-align: right;
  position: inherit;
  font-weight: bold;
  bottom: 2vh;
  right:-18%;
  font-size: 22%;
  color:var(--text-statsheet-color);
  z-index: 20;
}
.MName.Left{
  text-align: left;
  left:-18%;
}

.MImgHolder{
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 17vh;
  left:-1.3vw;
  /* background-color: red; */
}

.MImgHolder.Left{
  left:-0.075vw;
}


.MImg{
  position: relative;
  margin:auto;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  bottom: calc(-140% + var(--monster-size));
  transition: transform 0.2s;
  width: var(--monster-size);
  height: var(--monster-size);
  transform-origin: 54% 87.5%;
  z-index: 1;
  border: 0;
  /* filter: sepia(25%) hue-rotate(90deg) saturate(300%); */
}
.MImg.ZoomedOut{
  top: calc(var(--monster-size)*3/4);
  /* left: calc(var(--monster-size)/4); */
  width: calc(var(--monster-size)/2);
  height: calc(var(--monster-size)/2);
}
.MImg.Left{
  transform: scaleX(-1);
}
.MImg.Dead{
  transform: rotate(90deg);
}
.MImg.Left.Dead{
  transform: scaleX(-1) rotate(90deg);
}
.MImg.AnimAttack.Left{
  animation: attackLeft 1s;
  transition-timing-function: linear;
}
.MImg.AnimAttack.Right{
  animation: attackRight 1s;
  transition-timing-function: linear;
}
.MImg.AnimGetHit.Left{
  animation: getHitLeft 1s;
  transition-timing-function: linear;
}
.MImg.AnimGetHit.Right{
  animation: getHitRight 1s;
  transition-timing-function: linear;
}
.MImg.AnimDodge.Left{
  animation: dodgeLeft 2s;
  transition-timing-function: linear;
}
.MImg.AnimDodge.Right{
  animation: dodgeRight 2s;
  transition-timing-function: linear;
}
.MImg.AnimGetCrit.Left{
  animation: getCritLeft 1s;
  transition-timing-function: linear;
}
.MImg.AnimGetCrit.Right{
  animation: getCritRight 1s;
  transition-timing-function: linear;
}
.MImg.AnimResist.Right{
  animation: resistRight 0.5s;
  /* transition-timing-function: linear; */
}
.MImg.AnimResist.Left{
  animation: resistLeft 0.5s;
  /* transition-timing-function: linear; */
}

.FootShadow{
  position: absolute;
  left:44%;
  bottom: -30%;
  width: 11vmin;
}
.FootShadow.ZoomedOut{
  width: 6vmin;
  bottom: -29%;
}
.FootShadow.AnimAttack.Left{
  animation: attackLeftS 1s;
  transition-timing-function: linear;
}
.FootShadow.AnimAttack.Right{
  animation: attackRightS 1s;
  transition-timing-function: linear;
}
.FootShadow.AnimDodge.Left{
  animation: dodgeLeft 2s;
  transition-timing-function: linear;
}
.FootShadow.AnimDodge.Right{
  animation: dodgeRight 2s;
  transition-timing-function: linear;
}
.FootShadow.AnimGetHit.Left{
  animation: getHitLeftS 1s;
  transition-timing-function: linear;
}
.FootShadow.AnimGetHit.Right{
  animation: getHitRightS 1s;
  transition-timing-function: linear;
}


.MImgBG{
  position: relative;
  margin:auto;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  object-fit: contain;
  bottom: calc(-107% + 2 * var(--monster-size) + 1vw);
  transition: transform 0.2s;
  width: var(--monster-size);
  height: var(--monster-size);
  transform-origin: 54% 87.5%;
  left: -0.15vw;
  filter: sepia(100%) saturate(400%) brightness(0%) hue-rotate(50deg);
  z-index: 0;
  /* background-color: red; */
}
.MImgBG.Right{
  left: 0.15vw;
}





.HPBar{
  position: absolute;
  left: 5%;
  width: 6.56vw;
  height: 1.19vh;
  background-color: var(--hp-bg-color);
  padding: 0.1vw;
  /* padding-top: 0.1vw; */
  margin: auto;
  top: 0.25vw;
  z-index: 1;
}
.HPBarKnob{
  position: absolute;
  left: 99.9%;
  width: 1.7%;
  height: 60%;
  background-color: var(--hp-bg-color);
  margin: auto;
  top: 20%;
  z-index: -1;
}
.HPBarKnob.Right{
  left:-1.6%;
  /* height:20vw; */
}
.HPBar.Right{
  right:-57%;
}

.HPHeart{
  position: absolute;
  left:-18.5%;
  top:-45%;
}
.HPHeart.Right{
  left:97.5%;
}

.BarBG{
  width: 100%;
  height: 100%;
  background-color: var(--hp-bg-color);
  /* padding: 0.1vw; */
}
.BarFGHolder{
  float: left;
  width: calc(100% - 0.2vw);
  position: relative;
}
.BarFGHolder.Left{
  left: 0.1vw;
}
.BarFGHolder.Right{
  float: right;
  
  right: 0.1vw;
}
.BarFG{
  position: absolute;
  /* float: left; */
  /* width: 50%;
  height: 100%; */
  /* width: 100%; */
  height: 0.8vh;
}
.BarFG.Green{
  background-color: #4bb346;
  z-index: 5;
}
.BarFG.DGreen{
  
  background-color: #32752f;
  transition-timing-function: ease-in;
  transition: width 1s;
  z-index: 4;
  /* top: -1vh; */
}
.BarFG.Red{
  background-color: #b34646;/*CE2929*/
  z-index: 3;
  transition-timing-function: ease-in;
  transition: width 1s;
}
.BarFG.Black{
  background-color: var(--hp-bg-color);/*CE2929*/
  z-index: 2;
  transition-timing-function: ease-in;
  transition: width 1s;
}
.BarFG.Grey{
  background-color: grey;/*CE2929*/
  z-index: 1;
}
.BarFG.Right{
  right: 0%;
  /* float:right; */
}

.AIcon{
  position: absolute;
  font-size: 150%;
  left: 23.3vw;
  bottom: 3%;
  width: 10vw;
  text-align: center;
  /* font-family: var(--ht-font); */
  font-weight: bold;
  -webkit-text-stroke-width: 0.05vw;
  -webkit-text-stroke-color: black;
  /* background-color: azure; */
}

.BWVS{
  position: absolute;
  font-size: 250%;
  /* margin-left: auto; */
  left: 0%;
  top: 60%;
  width: 100%;
  /* background-color: red; */
  text-align: center;
}

.BWDist{
  position: absolute;
  font-size: 125%;
  left: 0%;
  /* margin: auto; */
  /* margin-right: auto; */
  top: 94.5%;
  width: 100%;
  text-align: center;
}





.ChatEntry{
  /* border: var(--border-size) black solid; */
  /* margin-bottom: calc(var(--padding-size) * 1.5);
  margin-top: var(--padding-size); */
  /* border-radius: var(--border-size); */
  /* background-color: gray; */
  font-size: 90%;
  padding-bottom: 0vw;
  float: left;
  margin-top: 0.25vw;
  width: calc(100% + 0.9vw);
  /* background-color: rgba(255,255,255,0.8); */
}
.ChatEntry.Right{
  margin-left: -0.9vw;
  margin-right: 0.9vw;
}
.ChatName{
  /* width:100%; */
  /* background-color: red; */
}
.ChatName.TeamRed{
  color:#97ed39;
}
.ChatName.TeamBlue{
  color:#f2df71;
}
.ChatName.TeamUndecided{
  color:gray;
}
.ChatName.Mod{
  color:var(--color-purple);
}
.ChatName.Donor{
  /* color:rgb(68, 165, 29); */
  color: var(--highlight-color);
}
.ChatName.MonthDonor{
  /* color:rgb(228, 100, 117); */
  color: var(--color-yellow-bold);
}
.ChatIcon{
  width:2vw;
  height:2vw;
  /* border:2px gray solid; */
  border-radius: 2vw;
  position: absolute;
  left:-2.25vw;
  bottom: -1.65vw;
  /* top:-.05vw; */
  background-color: #333333;
  padding:0;
  
}

.ChatIcon.Right{
  left:16.15vw;
}
.ChatIcon.FG{
  width:2vw;
  height:2vw;
  border:.2vw gray solid;
  border-radius: 2vw;
  position: absolute;
  left:0vw;
  bottom: 0vw;
  z-index: 10;
  background-color: transparent;
}
.ChatIcon.FG.TeamRed{
  border-color: #97ed39;
}
.ChatIcon.FG.TeamBlue{
  border-color: #f2df71;
}
.ChatCoin{
  width: .65vw;
}

/* .ChatIcon.FG.Right{
  left:0vw;
  bottom: 0vw;
} */

.CIHolder{
  position: absolute; 
  float: left;
  
}

.CTHolder{
  position: relative;
  /* background-color: #84DB9F; */
  min-height: 2vw;
  top:-0.4vw;
  width:15.9vw;
  float: left;
  font-size: 80%;
}
.CTHolder.Right{
  float:right;
  /* background-color: red; */
  text-align: right;
}
  /* position: relative; */
  /* float: left; */
  /* display: table;
  left: -.4vw;
  top: -.6vw; */
  /* border: solid black var(--border-size);
  border-radius: var(--border-size); */
  /* padding: 0.2vw; */
  /* background-color: rgba(200,200,200,1); */
/* } */

.ChatText{
  
  /* top: -.5vw; */
  /* position: relative; */
  /* padding: 0vw 0vw 0vw 0.15vw; */
  /* height: 100%; */
}



/* .ChatInput{
  width: calc(100% - 8vw);
  border: var(--border-size) solid rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,0);
  font-size: 140%;
  position: relative;
  top: .2vw;
} */

.BetsBox{
  width: calc(0.2 * (100% - (var(--margin-size) * 4)));
  height: calc(var(--ss-height) - (var(--margin-size) ) - var(--bw-height));
  top: calc(var(--bw-height) + var(--margin-size) * 2 + var(--topbar-height));
  overflow: hidden;
}

.BetHolder{
  margin-top: .2vw;
  font-size: 125%;
  /* text-align: center; */
  /* width: 100%; */
}

.Button{
  border: var(--border-size) black solid;
  background-color: var(--topbar-color);
  margin: .13vw;
  float: left;
  text-align: center;
  cursor: pointer;
}

.BetButton{
  width: var(--button-size);
}
.BetButton.TeamBlue{
  float: right;
}

.GoldButton{
  width: calc((100% - 0.26vw - var(--button-size) * 2) / 10 - 0.29vw);
}
.GoldButton.Active{
  background-color:yellow;
}
.GoldButton.Inactive{
  background-color:gray;
}
.GoldButton.Invalid{
  background-color:rgba(0,0,0,0.2);
  color: rgba(0,0,0,0.6)
}

.Shaker{
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.TeamRed{
  /* background-color: var(--team-red-color); */
  border-color: var(--team-red-border-color);
}
.TeamBlue{
  /* background-color: var(--team-blue-color); */
  border-color: var(--team-blue-border-color);
}
/* .ChatName.TeamRed{
  background-color: rgb(255,200,200);
}
.ChatName.TeamBlue{
  background-color: rgb(200,200,255);
} */



.TheFade{
  background-color: rgba(0,0,0,0.75);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  left: 0vw;
  top: 0vw;
}

.NamePreview{
  background-color: rgba(0,0,0,0.2);
  padding: .14vw;
  border-radius: .2vw;
}

.ConfirmButton{
  position: absolute;
  background-color: rgba(0,255,0,0.75);
  margin: var(--margin-size);
  padding: var(--padding-size);
  border-radius: .4vw;
  margin-left: 25%;
  cursor: pointer;
  position: absolute;
  bottom: 5%;
}

.RandomButton{
  position: absolute;
  bottom: 5%;
  background-color: rgba(255,255,0,0.75);
  margin: var(--margin-size);
  padding: var(--padding-size);
  border-radius: .4vw;
  right: 25%;
  cursor: pointer;
}

.WelcomeHeader{
  font-size: 200%;
  margin-bottom: var(--margin-size);
  text-align: center;
}

.ExtraInfo{
  position: fixed;
  right: calc(var(--topbar-height) / 6);
  top: calc(var(--topbar-height) / 6);
  
  /* border: var(--border-size) solid black; */

}

.InfoButton{
  z-index: 4;
  border: var(--border-size) solid rgba(0,0,0,0.75);
  border-radius: 3vw;
  width: calc(var(--topbar-height) / 6 * 4);
  height: calc(var(--topbar-height) / 6 * 4);
  background-color: rgba(100,255,100,0.4);
  color: rgba(0,0,0,0.75);
  text-align: center;
  font-size: 150%;
  cursor: pointer;
}

.ShownInfo{
  position: fixed;
  width: 12vw;
  /* height: 40vw; */
  background-color: rgb(50,50,50);
  border: var(--border-size) solid black;
  right: 1vw;
  top: var(--topbar-height);
  z-index: 25;
}

.InfoBox{
  /* background-color: var(--window-color); */
  background-color: var(--color-brown-mid);
  border: var(--border-size) solid black;
  padding: 0.1vw 0.5vw 0.2vw 0.5vw;
}

a:visited{
  color:cyan;
}

.SmallHeader{
  font-size: 80%;
  font-style: italic;
  /* text-decoration: underline; */
}

@keyframes shake {
  0% { transform: translate(calc( var(--shake-size) * 1), calc( var(--shake-size) * 1)) rotate(0deg); }
  10% { transform: translate(calc( var(--shake-size) * -1), calc( var(--shake-size) * -2)) rotate(-1deg); }
  20% { transform: translate(calc( var(--shake-size) * -3), 0vw) rotate(1deg); }
  30% { transform: translate(calc( var(--shake-size) * 3), calc( var(--shake-size) * 2)) rotate(0deg); }
  40% { transform: translate(calc( var(--shake-size) * 1), calc( var(--shake-size) * -1)) rotate(1deg); }
  50% { transform: translate(calc( var(--shake-size) * -1), calc( var(--shake-size) * 2)) rotate(-1deg); }
  60% { transform: translate(calc( var(--shake-size) * -3), calc( var(--shake-size) * 1)) rotate(0deg); }
  70% { transform: translate(calc( var(--shake-size) * 3), calc( var(--shake-size) * 1)) rotate(-1deg); }
  80% { transform: translate(calc( var(--shake-size) * -1), calc( var(--shake-size) * -1)) rotate(1deg); }
  90% { transform: translate(calc( var(--shake-size) * 1), calc( var(--shake-size) * 2)) rotate(0deg); }
  100% { transform: translate(calc( var(--shake-size) * 1), calc( var(--shake-size) * -2)) rotate(-1deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes attackLeft {
  0% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  20% { transform: translate(calc(var(--attack-size) * -0.2),0) rotate(-15deg) scaleX(-1); }
  40% { transform: translate(var(--attack-size),0) rotate(15deg) scaleX(-1); }
  70% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
}
@keyframes attackLeftS {
  0% { transform: translate(0,0) scaleX(-1); }
  20% { transform: translate(calc(var(--attack-size) * -0.2),0) scaleX(-1); }
  40% { transform: translate(var(--attack-size),0) scaleX(-1); }
  70% { transform: translate(0,0) scaleX(-1); }
  100% { transform: translate(0,0) scaleX(-1); }
}

@keyframes attackRight {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  20% { transform: translate(calc(var(--attack-size) * 0.2),0) rotate(15deg) scaleX(1); }
  40% { transform: translate(calc(var(--attack-size) * -1),0) rotate(-15deg) scaleX(1); }
  70% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(1); }
}
@keyframes attackRightS {
  0% { transform: translate(0,0) scaleX(1); }
  20% { transform: translate(calc(var(--attack-size) * 0.2),0) scaleX(1); }
  40% { transform: translate(calc(var(--attack-size) * -1),0) scaleX(1); }
  70% { transform: translate(0,0) scaleX(1); }
  100% { transform: translate(0,0) scaleX(1); }
}

@keyframes getHitLeft {
  0% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  40% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  45% { transform: translate(-5vw,0) rotate(-45deg) scaleX(-1); }
  60% { transform: translate(1vw,0) rotate(15deg) scaleX(-1); }
  70% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
}
@keyframes getHitLeftS {
  0% { transform: translate(0,0) scaleX(-1); }
  40% { transform: translate(0,0) scaleX(-1); }
  45% { transform: translate(-5vw,0) scaleX(-1); }
  60% { transform: translate(1vw,0) scaleX(-1); }
  70% { transform: translate(0,0) scaleX(-1); }
  100% { transform: translate(0,0) scaleX(-1); }
}

@keyframes getHitRight {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  40% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  45% { transform: translate(5vw,0) rotate(45deg) scaleX(1); }
  60% { transform: translate(-1vw,0) rotate(-15deg) scaleX(1); }
  70% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(1); }
}
@keyframes getHitRightS {
  0% { transform: translate(0,0) scaleX(1); }
  40% { transform: translate(0,0) scaleX(1); }
  45% { transform: translate(5vw,0) scaleX(1); }
  60% { transform: translate(-1vw,0) scaleX(1); }
  70% { transform: translate(0,0) scaleX(1); }
  100% { transform: translate(0,0) scaleX(1); }
}

@keyframes dodgeLeft {
  0% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  20% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  25% { transform: translate(0,8vw) rotate(-25deg) scaleX(-1); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
}

@keyframes dodgeRight {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  20% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  25% { transform: translate(0,8vw) rotate(25deg) scaleX(1); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(1); }
}

@keyframes getCritLeft {
  0% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  40% { transform: translate(0,0) rotate(0deg) scaleX(-1); }
  45% { transform: translate(-5vw,0) rotate(-45deg) scaleX(-1); }
  60% { transform: translate(1vw,0) rotate(-240deg) scaleX(-1); }
  70% { transform: translate(0,0) rotate(-450deg) scaleX(-1); }
  100% { transform: translate(0,0) rotate(-360deg) scaleX(-1); }
}

@keyframes getCritRight {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  40% { transform: translate(0,0) rotate(0deg) scaleX(1); }
  45% { transform: translate(-5vw,0) rotate(45deg) scaleX(1); }
  60% { transform: translate(1vw,0) rotate(240deg) scaleX(1); }
  70% { transform: translate(0,0) rotate(450deg) scaleX(1); }
  100% { transform: translate(0,0) rotate(360deg) scaleX(1); }
}

@keyframes resistRight {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1) scaleY(1); }
  25% { transform: translate(0,0) rotate(0deg) scaleX(1.25) scaleY(1.25); }
  50% { transform: translate(0,0) rotate(0deg) scaleX(1.1) scaleY(1.1); }
  75% { transform: translate(0,0) rotate(0deg) scaleX(1.25) scaleY(1.25); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(1) scaleY(1); }
}

@keyframes failSaveRight {
  0% { transform: translate(0,0) rotate(0deg) scaleX(1) scaleY(1); }
  25% { transform: translate(0,0) rotate(0deg) scaleX(0.75) scaleY(0.75); }
  50% { transform: translate(0,0) rotate(0deg) scaleX(0.9) scaleY(0.9); }
  75% { transform: translate(0,0) rotate(0deg) scaleX(0.75) scaleY(0.75); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(1) scaleY(1); }
}

@keyframes resistLeft {
  0% { transform: translate(0,0) rotate(0deg) scaleX(-1) scaleY(1); }
  25% { transform: translate(0,0) rotate(0deg) scaleX(-1.25) scaleY(1.25); }
  50% { transform: translate(0,0) rotate(0deg) scaleX(-1.1) scaleY(1.1); }
  75% { transform: translate(0,0) rotate(0deg) scaleX(-1.25) scaleY(1.25); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(-1) scaleY(1); }
}

@keyframes failSaveLeft {
  0% { transform: translate(0,0) rotate(0deg) scaleX(-1) scaleY(1); }
  25% { transform: translate(0,0) rotate(0deg) scaleX(-0.75) scaleY(0.75); }
  50% { transform: translate(0,0) rotate(0deg) scaleX(-0.9) scaleY(0.9); }
  75% { transform: translate(0,0) rotate(0deg) scaleX(-0.75) scaleY(0.75); }
  100% { transform: translate(0,0) rotate(0deg) scaleX(-1) scaleY(1); }
}